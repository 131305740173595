<template>
	<div v-if="item">
		<div class="d-flex mb-2">
			<div class="w-25">
				<img class="width-image" :src="item.thumbnail">
			</div>
			<div class="pt-4">
				<div class="color-black">{{ item.name }}</div>
				<div class="color-black-45">{{ item.sku }}</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'ProductCompareEditItem',
	props: {
		item: {
			type: Object,
			default: null,
		},
	},
};
</script>
<style lang="scss" scoped>
.width-image {
	width: 100%;
	max-width: 100px;
}
</style>
