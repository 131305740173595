import { CATEGORY_DISPLAY_TYPE } from '../enums/categories';

export const STATUS = {
	[true]: 'page.categories.status.active',
	[false]: 'page.categories.status.inActive',
};

export const VISIBILITY = {
	[true]: 'page.categories.visibility.on',
	[false]: 'page.categories.visibility.off',
};

export const EXPAND = {
	[true]: 'page.categories.expand.on',
	[false]: 'page.categories.expand.off',
};

export const CATEGORY_DISPLAY = {
	[CATEGORY_DISPLAY_TYPE.LOB]: 'LOB PAGE',
	[CATEGORY_DISPLAY_TYPE.PLP]: 'PLP PAGE',
	[CATEGORY_DISPLAY_TYPE.SEARCH_LIST]: 'SEARCH PAGE',
};

export const ENABLE = {
	[true]: 'Enable',
	[false]: 'Disable',
};