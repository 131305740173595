<template>
	<li class="compare-item">
		<div class="compare-info d-flex align-items-center">
			<span data-test-id="compare-title" class="compare-title typo-body-2">{{ data.titleEN }}</span>
		</div>
		<div class="compare-actions">
			<CBadge class="badge-status mr-4" :color="data.status.value">
				{{ data.status.name }}
			</CBadge>
			<!-- ? Hide cuz this feature is not functional in compare item we using priority for sorting data -->
			<!-- <a
				href=""
				class="mr-4"
				data-test-id="move-to-top"
				@click.prevent="$emit('onMoveToTop')"
			>
				<CIcon class="cil-vertical-align-top" name="cil-vertical-align-top" />
			</a> -->
			<a
				href=""
				class="mr-4"
				data-test-id="edit"
				@click.prevent="$emit('onEdit')"
			>
				<CIcon class="cil-pencil" name="cil-pencil" />
			</a>
			<a
				href=""
				class="mr-4"
				data-test-id="edit"
				@click.prevent="$emit('onDelete')"
			>
				<CIcon class="cil-trash" name="cil-trash" />
			</a>
		</div>
	</li>
</template>

<script>
export default {
	name: 'CompareItem',
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
};
</script>

<style lang="scss" scoped>
.compare-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: rem(6) rem(12);
	background-color: $color-white;
	margin-bottom: rem(2);
	cursor: move;

	&:hover {
		background-color: $color-gray-200;
	}

	.compare-title {
		@include ellipsisOneline();

		max-width: rem(320);
		display: inline-block;
	}

	.cil-vertical-align-top,
	.cil-trash,
	.cil-pencil {
		color: $color-gray-400;

		&:hover {
			color: $color-gray-800;
		}
	}
}
</style>
