<template>
	<form class="main-wrapper" @submit.prevent="handleSubmit">
		<!--General info-->
		<template name="general-info">
			<div class="d-flex justify-content-between align-items-center mb-4">
				<h4>
					General info
				</h4>
			</div>
			<div>
				<CInput
					v-model="$v.formData.name.$model"
					:is-valid="!$v.formData.name.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Product compare name*"
				/>
			</div>
			<div class="form-group form-group-switch mt-1">
				<label class="toggle-label" for="status">Product compare status</label>
				<CSwitch
					id="status"
					:checked.sync="formData.status"
					variant="3d"
					size="sm"
					class="switch-custom"
					color="success"
					@update:checked="handleChangeStatus"
				/>
				<span>{{ formData.status ? 'Active' : 'Inactive' }}</span>
			</div>
		</template>
		<hr>
		<template name="compare-text">
			<CRow>
				<CCol md="6">
					<CInput
						v-model.trim="formData.compareTextEn"
						label="Compare Text EN"
					/>
				</CCol>
				<CCol md="6">
					<CInput
						v-model.trim="formData.compareTextTh"
						label="Compare Text TH"
					/>
				</CCol>
			</CRow>

			<CRow>
				<CCol md="6">
					<CInput
						v-model.trim="formData.compareDescriptionEn"
						label="Compare Description EN"
					/>
				</CCol>
				<CCol md="6">
					<CInput
						v-model.trim="formData.compareDescriptionTh"
						label="Compare Description TH"
					/>
				</CCol>
			</CRow>
		</template>
		<!-- Product Compare additional content -->
		<template name="product-compare-additional-content">
			<CRow>
				<CCol class="md=6 float-right">
					<h6>
						Product Compare additional content
					</h6>
					<div class="color-black-45 label">Product compare will be displayed at the bottom of the product detail page</div>
				</CCol>
				<CCol class="md=6">
					<CButton
						class="transparent btn-right"
						color="secondary"
						@click="$refs['modal-product-compare'].open()"
					>
						<CIcon class="icon-left" name="cil-plus" />
						Add Compare
					</CButton>
				</CCol>
			</CRow>
			<div class="product-compare-list mt-3" :class="{'d-flex align-items-center justify-content-center': !formData.htmlContents.length}">
				<draggable
					v-if="formData.htmlContents.length"
					:value="formData.htmlContents"
					ghost-class="is-placeholder-item"
					class="compare-list-dragable"
					@input="handleCompareOrder"
				>
					<CompareItem
						v-for="(data, index) in formData.htmlContents"
						:key="index"
						:data="data"
						class="compare-list-item"
						@onEdit="handleOnEditCompare(data, index)"
						@onDelete="handleOnDeleteCompare(index)"
					/>
				</draggable>
				<template v-else>
					<span class="color-black-45 text-no-content">No compare in the list</span>
				</template>
			</div>
			<hr>
		</template>
		<!-- Edit Product -->
		<template name="edit-product">
			<h3>Products</h3>
			<CRow class="row">
				<CCol col="6" class="color-black-45 label">These products are will show in PDP.</CCol>
				<CCol col="6">
					<CButton
						class="transparent btn-right"
						color="secondary"
						@click="$refs['modal-sku'].open()"
					>
						Edit products
					</CButton>
				</CCol>
			</CRow>
			<div v-if="formData.products.length">
				<h6 class="pt-3">SKUs/Categories</h6>
				<h6 class="color-black pl-3">Default</h6>
				<div class="rounded border p-2">
					<ProductCompareEditItem
						v-for="(item,index) in formData.products"
						:key="index"
						:item="item"
					/>
				</div>
			</div>
			<div v-else class="mt-4 bg-no-data d-flex align-items-center justify-content-center">
				<span class="color-black-45 text-no-content">No product in the list</span>
			</div>
			<hr class="my-5">
		</template>
		<!--footer-->
		<template name="footer">
			<BaseActionPanelStickyFooter
				:remove-text="isEdit ? 'Remove Product Compare' : null"
				:is-edit="isEdit"
				:disabled-confirm="isDisabledSubmit"
				:disabled-cancel="isDisabledSubmit"
				:disabled-remove="isDisabledSubmit"
				@onConfirm="handleSubmit"
				@onCancel="handleCancel"
				@onRemove="handleRemove"
			/>
		</template>

		<!--modal-->
		<ModalCategoryCompare ref="modal-product-compare" @onConfirm="handleAddCompare" />
		<ModalSKU
			ref="modal-sku"
			title="Add Products"
			:is-validate-product-compare="true"
			:sku-list="formData.products"
			@onConfirm="handleEditProduct"
		/>
	</form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
// product
import CompareItem from '@/components/CompareItem.vue';
import ProductCompareEditItem from '@/components/ProductCompareEditItem.vue';
// modal
import ModalCategoryCompare from '@/components/ModalCategoryCompare.vue';
import ModalSKU from '@/components/ModalSKU.vue';
// footer
import BaseActionPanelStickyFooter from '@/components/BaseActionPanelStickyFooter.vue';
// tranformed
import { transformedPayloadProductCompare } from '../assets/js/transform/productCompare';
import { scrollToTop } from '../assets/js/helpers';

export default {
	name: 'ProductCompareForm',
	components: {
		draggable,
		CompareItem,
		ProductCompareEditItem,
		ModalCategoryCompare,
		ModalSKU,
		BaseActionPanelStickyFooter,
	},
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		inputValue: {
			type: Object,
			default: null,
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
	},
	validations: {
		formData: {
			name: {
				required,
			},
		},
	},

	data() {
		return {
			formData: {
				name: null,
				status: false,
				compareTextEn: null,
				compareTextTh: null,
				compareDescriptionEn: null,
				compareDescriptionTh: null,
				htmlContents: [],
				products: [],
			},
			nameTH: null,

			isDrag: false,
		};
	},
	computed: {
		isDisabledSubmit() {
			return this.isLoading;
		},
	},
	watch: {
		inputValue() {
			this.setFormData();
		},
	},
	created() {
		this.setFormData();
	},
	methods: {
		setFormData() {
			if (this.inputValue) {
				this.formData.name = this.inputValue.name;
				this.formData.status = this.inputValue.status;
				this.formData.compareTextEn = this.inputValue.compareTextEn;
				this.formData.compareTextTh = this.inputValue.compareTextTh;
				this.formData.compareDescriptionEn = this.inputValue.compareDescriptionEn;
				this.formData.compareDescriptionTh = this.inputValue.compareDescriptionTh;
				this.formData.htmlContents = this.inputValue.htmlContents;
				this.formData.products = this.inputValue.products;
			}
		},
		handleChangeStatus(value) {
			this.formData.status = value;
		},
		handleAddCompare(product, isChangePriority) {
			if (product.index !== null) {
				this.formData.htmlContents[product.index] = product;
			} else {
				this.formData.htmlContents.push(product);
			}
			if (isChangePriority) {
				this.isDrag = false;
			}
			this.resetHtmlContents();
		},
		handleEditProduct(product) {
			this.formData.products = product;
		},
		handleCompareOrder(sortedList = []) {
			this.formData.htmlContents = sortedList;
			this.isDrag = true;
			this.resetHtmlContents();
		},
		sortHtmlContents() {
			if (this.isDrag) {
				this.formData.htmlContents.forEach((content, index) => {
					content.priority = index;
				});
			}
		},
		handleSubmit() {
			this.$v.$touch();
			if (!this.$v.$invalid) {
				this.sortHtmlContents();
				const payload = transformedPayloadProductCompare(this.formData);
				this.$emit('onSubmit', payload);
			} else {
				scrollToTop();
			}
		},
		handleCancel() {
			this.$router.push('/product-compare');
		},
		handleRemove() {
			this.$emit('onRemove');
		},
		// product compare
		handleOnEditCompare(data, index) {
			this.$refs['modal-product-compare'].open(data, index);
		},
		handleOnDeleteCompare(index) {
			this.formData.htmlContents = this.formData.htmlContents.filter((_, i) => i !== index);
			this.resetHtmlContents();
		},
		resetHtmlContents() {
			const htmlContentsTemp = this.formData.htmlContents;
			this.formData.htmlContents = [];

			this.$nextTick(() => {
				this.formData.htmlContents = htmlContentsTemp;
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.btn-right {
	float: inline-end;
}

.product-compare-list {
	background-color: $color-gray-100;
	min-height: 200px;
	padding: 4px;
	border-radius: 4px;
}

.bg-no-data {
	background-color: $color-gray-100;
	min-height: 200px;
	padding: 4px;
	border-radius: 4px;
}

.label {
	font-size: rem($font-size-caption);
}

.text-no-content {
	font-size: rem($font-size-subtitle);
}
</style>
