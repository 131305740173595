<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="currentData ? `Edit compare modal` : title"
					@onClose="close"
				/>
			</template>
			<template #default>
				<CRow>
					<CCol md="5">
						<CInput
							v-model.trim="$v.titleEN.$model"
							:is-valid="!$v.titleEN.$error && null"
							:invalid-feedback="$t('global.error.required')"
							data-id="compare-title-en"
							label="Title (EN)*"
						/>
					</CCol>
					<CCol md="5">
						<CInput
							v-model.trim="$v.titleTH.$model"
							:is-valid="!$v.titleTH.$error && null"
							:invalid-feedback="$t('global.error.required')"
							data-id="compare-title-th"
							label="Title (TH)*"
						/>
					</CCol>
					<CCol md="2">
						<CInput
							v-model.trim="$v.priority.$model"
							:is-valid="!$v.priority.$error && null"
							:invalid-feedback="$t('global.error.required')"
							data-id="compare-priority"
							type="number"
							label="Priority*"
							@input="handlePriorityInput"
						/>
					</CCol>
					<CCol md="12" class="d-flex gap-3 mb-2">
						<label class="toggle-label" for="compare-status">
							Status
						</label>
						<CSwitch
							id="compare-status"
							data-id="compare-status"
							:checked="isActive"
							variant="3d"
							size="sm"
							class="switch-custom toggle-status"
							color="success"
							@update:checked="handleUpdateStatus"
						/>
						<span>{{ statusText }}</span>
					</CCol>
					<CCol md="12">
						<label>Content (EN)</label>
						<!-- force to recomponent when isShow changed -->
						<BaseRichTextEditor
							v-if="isShow"
							v-model="$v.contentEN.$model"
							:is-valid="!$v.contentEN.$error"
							:invalid-feedback="$t('global.error.required')"
							name="Content (EN)*"
						/>
					</CCol>
					<CCol md="12">
						<label>Content (TH)</label>
						<!-- force to recomponent when isShow changed -->
						<BaseRichTextEditor
							v-if="isShow"
							v-model="$v.contentTH.$model"
							:is-valid="!$v.contentTH.$error"
							:invalid-feedback="$t('global.error.required')"
							name="Content (TH)*"
						/>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					@onCancel="close"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
	</div>
</template>


<script>
import { required } from 'vuelidate/lib/validators';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import {
	STATUS as mappingStatus,
} from '../mapping/categories';
import { PRODUCT_LABEL_STATUSES } from '../enums/productLabels';


export default {
	name: 'ModalCategoryCompare',
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
	},
	validations: {
		titleEN: {
			required,
		},
		titleTH: {
			required,
		},
		priority: {
			required,
		},
		contentEN: {
			required,
		},
		contentTH: {
			required,
		},
	},
	props: {
		title: {
			type: String,
			default: 'Create compare modal',
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShow: false,
			currentData: null,
			isChangePriority: false,

			// form data
			index: null,
			isActive: false,
			titleEN: null,
			titleTH: null,
			contentEN: null,
			contentTH: null,
			priority: 10,
		};
	},
	computed: {
		statusText() {
			const text = mappingStatus[this.isActive] || '';
			return this.$t(text);
		},
	},
	methods: {
		open(data = null, index = null) {
			if (data && index !== null) {
				this.index = index;
				this.currentData = data;
				this.initData();
			}

			this.isShow = true;
		},
		close() {
			this.resetState();
			this.isShow = false;
		},

		// form data
		initData() {
			if (!this.currentData) return;

			this.isActive = this.currentData.isActive;
			this.titleEN = this.currentData.titleEN;
			this.titleTH = this.currentData.titleTH;
			this.contentEN = this.currentData.contentEN;
			this.contentTH = this.currentData.contentTH;
			this.priority = this.currentData.priority;
		},
		resetState() {
			this.$v.$reset();
			this.currentData = null;
			this.isChangePriority = false;

			// form data
			this.index = null;
			this.isActive = false;
			this.titleEN = null;
			this.titleTH = null;
			this.contentEN = null;
			this.contentTH = null;
			this.priority = 10;
		},
		handleUpdateStatus(value) {
			this.isActive = value;
		},
		handlePriorityInput() {
			this.isChangePriority = true;
		},
		handleSubmit() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				const status = this.isActive
					? PRODUCT_LABEL_STATUSES.active
					: PRODUCT_LABEL_STATUSES.inactive;
				this.$emit('onConfirm', {
					index: this.index,
					status,
					isActive: this.isActive,
					titleEN: this.titleEN,
					titleTH: this.titleTH,
					contentEN: this.contentEN,
					contentTH: this.contentTH,
					priority: this.priority,
				}, this.isChangePriority);
				this.close();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.gap-3 {
	gap: rem(16);
}

::v-deep .modal-dialog {
	max-width: rem(900);
}
</style>
